<template>
  <div>
    <Header
        v-model="headerLinks" v-bind:headerLinks="headerLinks"
    ></Header>

    <div class="container mt-2">

      <button class="btn btn-warning" style="border-radius: 12px;"><i class="fa fa-plus"></i> Добавить</button>
      <div class="row">
        <DatatableDefaultVocabs :datatableId="'dt2'" :tableData="vocabsData" :columnsData="vocabsColumnsData"/>
      </div>

    </div>

  </div>
</template>

<script>
import Header from "@/components/Header";
import DatatableDefaultVocabs from "@/components/Datatables/DatatableDefaultVocabs";

export default {
  name: "DefaultVocabs",
  components: {
    Header,
    DatatableDefaultVocabs
  },
  data () {
    return {
      headerLinks: [
        {
          name: "Настройка предустановленных словарей",
          url: "/default_vocabs"
        },
      ],
      vocabsData: [
        {
          id: 1,
          name: "вежливые фразы",
          words: "добрый день, спасибо, пожалуйста",
          description: "вежливые фразы"
        },
        {
          id: 2,
          name: "вежливые фразы 2",
          words: "добрый день, спасибо, пожалуйста",
          description: "вежливые фразы"
        },
        {
          id: 1,
          name: "вежливые фразы 3",
          words: "добрый день, спасибо, пожалуйста",
          description: "вежливые фразы"
        },
      ],
      vocabsColumnsData: [
        { title: "Название", visible: true },
        { title: "Слова", visible: true },
        { title: "Описание", visible: true },
        { title: "Редактировать", visible: true },
        { title: "Удалить", visible: true }
      ]
    }
  }
}
</script>

<style scoped>

</style>