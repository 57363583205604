<template>
  <div>
    <div class="table_margin mb-2 mt-3">
      <table class="table table-hover table-borderless" :id="datatableId">
      <thead>
      <tr>
        <th v-for="(column, index) in columnsData" :key="index">
          <a v-if="column.visible">{{ column.title }}
          </a>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="user in users" :key="user.id">
        <td>{{user.name}}</td>
        <td>{{user.words}}</td>
        <td>{{user.description}}</td>
        <td>
<!--          <button class="btn btn-primary"-->
<!--                  @click="openReport(user)"-->
<!--          ><i class="fa fa-pen"></i></button>-->

          <button class="btn btn-lg btn-outline-$gray300" style="border-radius: 12px; color: #000000;"
                  @click="modalUsers = !modalUsers"
          ><i class="fa fa-pen"></i></button>

        </td>
        <td>
          <button class="btn btn-lg btn-outline-$gray300" style="border-radius: 12px; color: #000000;"><i class="fa fa-times"></i></button>
        </td>
      </tr>
      </tbody>
    </table>
    </div>

    <div v-if="modalUsers" class="modal">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Подсказка</h5>
              <button type="button" class="close" data-dismiss="modal">
                <span>×</span>
              </button>
            </div>
            <div class="modal-body">
              <p>Статистика по загруженным пользователем данным</p>
            </div>
          </div>
        </div>

    </div>

  </div>
</template>

<script>

// import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';

export default {
  name: "DatatableDefaultVocabs",
  props: {
    datatableId: String,
    tableData: [],
    columnsData: []
  },
  data() {
    return {
      users: [],
      demoData: [['0','1','2','3','4','5','6','7','8','9','10','11','12']],
      modalUsers: false,
      modalGroups: false
    }
  },
  mounted(){
    this.users = this.tableData
    this.demoData = []
    // for (let v in this.columnsData) {
    //   if (this.columnsData[v].visible) {
    //     this.demoData.push(' ')
    //   }
    // }
    
    console.log("this.demoData", this.demoData)
    
    $('#'+this.datatableId).DataTable({

      order: [[ 1, "desc" ]],
          "pageLength": 10,
        "lengthMenu": [ 10, 25, 50, 75, 100 ],
        "lengthChange": true,
        "pagingType": "first_last_numbers",
//			"searching": true,
        "dom": '<"top text-right"f><"top text-left"i>t<"bottom text-right"l><"bottom text-center"p><"clear">',
//			"dom": "<'row'<'col-md-3'l><'col-md-5 'p><'col-md-4 text-right'i><'col-sm-12'tr>>",

        // data: this.users,
        data: [],
        // data: this.demoData,
        columns: this.columnsData,

        "columnDefs": [
    ],
        language: {
          "processing": "Подождите...",
          "search": "Поиск:",
          "lengthMenu": "Показать _MENU_ записей",
          "info": "Записи с _START_ до _END_ из _TOTAL_ записей",
          "infoEmpty": "Записи с 0 до 0 из 0 записей",
          "infoFiltered": "(отфильтровано из _MAX_ записей)",
          "infoPostFix": "",
          "loadingRecords": "Загрузка записей...",
          "zeroRecords": "Записи отсутствуют.",
          "emptyTable": "В таблице отсутствуют данные",
          "paginate": {
            "first": " Первая ",
            "previous": "< Предыдущая ",
            "next": " Следующая >",
            "last": " Последняя "
      },
      "aria": {
        "sortAscending": ": активировать для сортировки столбца по возрастанию",
            "sortDescending": ": активировать для сортировки столбца по убыванию"
      }
    }
  });
  },
  methods: {
    openReport(reportData) {

      if (!reportData) {
        console.log('')
      }
      // this.$router.push({
      //   name: 'SaMainReport',
      //   params: {
      //     name: reportData.client_name
      //   }
      // })
    }
  }
}
</script>

<style scoped>

.table_margin {
  margin-left: 15px;
  margin-right: 15px;
}

</style>